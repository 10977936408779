<script setup lang="ts">
const { isImpersonating, session } = useAuth()
const { data: workspace } = await useWorkspaceSettings()
</script>
<template>
  <div
    class="relative isolate flex min-h-svh w-full bg-slate-100 max-lg:flex-col"
  >
    <DemoBadge v-if="workspace?.demo" />

    <TopNavigation />
    <SidebarNavigation />
    <main
      id="main-content"
      class="flex flex-1 flex-col lg:min-w-0 lg:pt-2 lg:pr-2 lg:pb-2 lg:transition-all lg:duration-200 lg:ease-in-out"
    >
      <div
        class="relative z-10 flex grow rounded-lg bg-white shadow-xs ring-1 ring-slate-950/5"
      >
        <div
          class="@container/main mx-auto flex grow flex-col-reverse xl:flex-row xl:divide-x xl:divide-slate-200"
        >
          <div class="grow" :class="$slots.right ? 'xl:w-3/4' : ''">
            <slot />
          </div>
          <div
            v-if="$slots.right"
            class="flex flex-col rounded-br-lg bg-slate-50 py-4 pr-4 pl-4 xl:w-1/4 xl:rounded-tr-lg xl:pr-2"
          >
            <slot name="right" />
          </div>
        </div>
      </div>
    </main>
    <NotificationToast />
    <ImpersonatingBadge
      v-if="session && isImpersonating"
      :email="session.user.email"
    />
    <DeveloperToolbox />
  </div>
</template>
